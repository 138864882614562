import { useState } from "react";

import { SlClose } from 'react-icons/sl'
import AiResponseField from "../ai-response-field/ai-response-field.component";
import AiInputField from "../ai-input-field/ai-input-field.component";
import './ai-chat-card.styles.scss';
import { useTranslation } from 'react-i18next';

export type ChatCardProps = {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    responseMessage: string[];
    setResponseMessage: React.Dispatch<React.SetStateAction<string[]>>;
    isChatActive: boolean;
    setIsChatActive: React.Dispatch<React.SetStateAction<boolean>>;
}


const AiChatCard = ({ message, responseMessage, setMessage, setResponseMessage, isChatActive, setIsChatActive }: ChatCardProps) => {
    // const user = "Example: 'Hello, who I am talking with?'";
    const { t } = useTranslation(["aiAssistant/aiAssistant"]);
    const user = t('UserMessageExample');
    const aiAssistant = t('AiAssistantGreeting');
    const title = t('CardTitle');
    const title1 = t('CardTitle1');


    const [isFocused, setIsFocused] = useState(false);

    const handleChat = () => {
        setIsChatActive(!isChatActive);
        setResponseMessage([user, aiAssistant]);
        setMessage('');
    }

    return (
        <div className={`ai-assistant-card ai-assistant-card--${isChatActive ? 'active' : 'inactive'}`}>
            <div className='ai-assistant-card__container'>

                <div className='ai-assistant-card__container__header'>
                    <p className='ai-assistant-card__container__header-title'>{title}</p>
                    <p className='ai-assistant-card__container__header-title-1'>{title1}</p>
                    <button type='button' aria-label="Ai assistant send message" className='ai-assistant-card__container__header-close-button' onClick={handleChat}><SlClose /></button>
                </div>
                <div className='close button'>
                    {/* <div className='ai-assistant-card__container__message-field-wave' >
                        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "100%", width: "100%" }}>
                            <path
                                d="M-0.96,125.96 C237.15,212.95 252.78,-35.03 501.52,26.95 L500.00,0.00 L-0.00,0.00 Z"
                                // d="M-0.58,
                                // 75.26 C180.32,
                                // 178.78 306.97,
                                // -59.26 500.53,
                                // 33.36 L500.53,
                                // 149.90 L-1.15,
                                // 149.90 Z"
                                style={{ stroke: "none", fill: "#D00" }}
                            />
                        </svg>
                    </div> */}
                    <AiResponseField responseMessage={responseMessage} isFocused={isFocused} />
                </div>
                <div className='ai-assistant-card__container__input-field'>
                    <AiInputField message={message} setMessage={setMessage} setResponseMessage={setResponseMessage} setIsFocused={setIsFocused} />
                </div>
                <div className='ai-assistant-card__container__footer'>
                    <p className="ai-assistant-card__container__footer-text">Powered by Red Digit AI</p>
                </div>
            </div>
        </div>
    )
}
export default AiChatCard