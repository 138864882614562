import { useRef, useEffect } from 'react'
import './ai-reponse-field.styles.scss'

type ResponseProps = {
    responseMessage: string[]
    isFocused: boolean
}


const AiResponseField = ({ responseMessage, isFocused }: ResponseProps) => {
    const scrollRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }, [responseMessage, isFocused]);

    return (
        <>
            {responseMessage.length
                ? <div className={`ai-card-response-field ai-card-response-field${isFocused ? '--height-short' : '--height-long'}`}>
                    <div className="ai-card-response-field__text">
                        {responseMessage.map((item, index) => {
                            const color = index % 2 == 0 ? 'lime-400' : 'amber-100'
                            return (index == 0 ? '' : <p key={index} className={`ai-card-response-field__text--${color}`}>{item}</p>)
                        }
                        )}
                    </div>
                    <div ref={scrollRef}></div>
                </div>
                : null}
        </>
    )
}

export default AiResponseField
