import './Development.styles.scss'
import { lazy, Suspense, useEffect, useState } from 'react';
import devHero from '/assets/hero-images/development-1000p.webp';

import { useTranslation } from 'react-i18next';
import AnimatedArrowsDown from '../../components/animated-arrows-down/AnimatedArrowsDown.component'

import Button from '../../components/button/button.component'
const ContentCard = lazy(() => import('../../components/content-card/ContentCard.component'));
const InfoCardColorSection = lazy(() => import('../../components/info-card-color-section/Info-card-color-section.component'));
const Footer = lazy(() => import('../../components/footer/footer.component'));

import { ContentCardData } from '../../application-data/content-cards-data';
// import InfoCardSimpleSection from '../../components/info-card-simple-section/Info-card-simple-section.component';
import { Link } from 'react-scroll';
import useComponentPath from '../../hooks/componentPath';


const Development = () => {


    const { t } = useTranslation([
        "routes/development",
        "contentCards/developmentContentCard",
        "contentCards/stripeContentCard"
    ]);
    // const { t: c } = useTranslation(["common/common"])
    useComponentPath('development');

    const developmentContentCard: ContentCardData = t('contentCards/developmentContentCard:developmentContentCard', { returnObjects: true })
    const stripeContentCard: ContentCardData = t('contentCards/stripeContentCard:stripeContentCard', { returnObjects: true })

    const handleClick = () => {
        const scrollOffset = window.scrollY + window.innerHeight * 0.8;
        window.scrollTo({ top: scrollOffset, left: 0, behavior: 'smooth' })
    }

    return (
        <section className="development-page">
            <div className="development-page__hero-container">
                <div className="development-page__hero">
                    <div className='development-page__hero-image' data-aos="zoom-in-left" data-aos-delay="100">
                        <img
                            src={devHero}
                            alt={'Red Digit development technologies hero image'}
                        />
                        {/* <LoadImage
                            src={'/assets/hero-images/development-1000p.webp'}
                            srcLq={'/assets/hero-images/development-1000p-low.png'}
                            alt={'Red Digit development technologies hero image'}
                        /> */}
                    </div>
                    <div className='development-page__hero-text'>
                        <h1 data-aos="zoom-in-right" data-aos-delay="100" data-aos-offset="-300">{t('DevelopmentTeam')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('ToolsExpertise')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('PartialsBuild')}</h1>
                        <p
                            data-aos="fade-right"
                            data-aos-delay="400"
                            data-aos-offset="-300"
                        >
                            {t('OurExperiencedTeam')}
                        </p>
                        <div className="development-page__hero-text-button" data-aos="fade-right" data-aos-delay="500" data-aos-offset="-300">
                            <Link to='development-page__main'>
                                <Button >{t('GetStarted')}</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="development-page__hero-arrows" onClick={handleClick}>
                        <AnimatedArrowsDown colors={['#FFF', '#FFF', '#FFF']} />
                    </div>
                </div>
            </div>
            <div className="development-page__main">
                <Suspense fallback={<div></div>}>
                    <ContentCard {...developmentContentCard} />
                    {/* <InfoCardSimpleSection /> */}
                    <InfoCardColorSection />
                    <ContentCard {...stripeContentCard} />
                    <br />
                    <br />
                    <Footer />
                </Suspense>
            </div>
        </section>
    )
}

export default Development
