export const ns = [
  'common/common',
  'aiAssistant/aiAssistant',
  'navbar/navbar',
  'components/hero',
  'components/aboutMeCard',
  'components/contact-form',
  'components/countersHomeData',
  'components/footer',
  'components/chatBotPointer',
  'infoCardsData/aboutMeData',
  'infoCardsSimpleData/devPageData',
  'featureCards/homeFeatures',
  'contentCards/homeContentCard',
  'contentCards/developmentContentCard',
  'contentCards/stripeContentCard',
  'contentCards/aiContentCard',
  'contentCards/uiUxContentCardUi',
  'contentCards/uiUxContentCardUx',
  'titleContentCards/titleContentCardsData',
  'routes/development',
  'routes/ai',
  'routes/uiUx',
  'routes/contact',
];
