import './Counter.styles.scss';
import CountUp from 'react-countup';
import RenderIfVisible from 'react-render-if-visible';

type CounterProps = {
    countFrom: number,
    countTo: number,
    content: string,
    countAddOn: string,
}

const Counter = ({ countFrom, countTo, content, countAddOn }: CounterProps) => {

    // the useCountup prevents errors because if enableScrollSpy={true}
    // It must be set when component is fully loaded

    return (
        <>
            <section className='counter'
                data-aos="zoom-in-down"
                data-aos-easing="ease-in"
                data-aos-duration="300">
                <div className="counter__number">
                    <RenderIfVisible defaultHeight={100} visibleOffset={50} stayRendered>
                        <CountUp
                            start={countFrom}
                            end={countTo}
                            duration={1.5}
                            redraw={true}
                            // enableScrollSpy={true}
                            useEasing={false}
                            scrollSpyOnce={true}
                            className='counter__number-countup'
                        />
                    </RenderIfVisible>
                    <p className="counter__number-addon">{countAddOn}</p>
                    <p id="counter" className='counter__number-countup' />
                </div>

                <div className='counter__separator'></div>
                <h2 className='counter__content'>
                    {content}
                </h2>
            </section>
        </>

    )
}
export default Counter