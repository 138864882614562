import './Ai.styles.scss'
import { lazy, Suspense, useState, useEffect } from 'react';
import aiHero from '/assets/hero-images/ai-hero-1000p.webp';
import Button from '../../components/button/button.component'
import { Link } from 'react-scroll';
import AnimatedArrowsDown from '../../components/animated-arrows-down/AnimatedArrowsDown.component'
// import LoadImage from '../../components/LoadImage/LoadImage.component'

const ContentCard = lazy(() => import('../../components/content-card/ContentCard.component'));
const TitleContentCard = lazy(() => import('../../components/title-content-card/TitleContentCard.component'));
const ChatBotPointer = lazy(() => import('../../components/chatBotPointer/ChatBotPointer.component'));
const Footer = lazy(() => import('../../components/footer/footer.component'));

import { ContentCardData } from '../../application-data/content-cards-data';
import { useTranslation } from 'react-i18next';

import useComponentPath from '../../hooks/componentPath';

const Ai = () => {
    const { t } = useTranslation(["routes/ai", "contentCards/aiContentCard"]);
    const aiContentCard: ContentCardData = t('contentCards/aiContentCard:aiContentCard', { returnObjects: true })
    const aiContentCard2: ContentCardData = t('contentCards/aiContentCard:aiContentCard2', { returnObjects: true })

    useComponentPath('ai-integrations')

    const handleClick = () => {
        const scrollOffset = window.scrollY + window.innerHeight * 0.8;
        window.scrollTo({ top: scrollOffset, left: 0, behavior: 'smooth' })
    }

    return (
        <section className="ai-page">
            <div className="ai-page__hero-container">
                <div className="ai-page__hero">
                    <div className='ai-page__hero-image' data-aos="zoom-in-left" data-aos-delay="100">
                        {/* <img src={aiHeroImage} alt="Computer art" /> */}
                        <img
                            src={aiHero}
                            alt={'Red Digit AI & LLM integrations hero image'}
                        />
                        {/* <LoadImage
                            src={'/assets/hero-images/ai-hero-1000p.webp'}
                            srcLq={'/assets/hero-images/ai-hero-1000p-low.png'}
                            alt={'Red Digit AI & LLM integrations hero image'}
                        /> */}
                    </div>
                    <div className='ai-page__hero-text'>
                        <h1 data-aos="zoom-in-right" data-aos-delay="100" data-aos-offset="-300">{t('AiLlmIntegrations')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('ToolsExpertise')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('AssistantAI')}</h1>
                        <p
                            data-aos="fade-right"
                            data-aos-delay="400"
                            data-aos-offset="-300"
                        >
                            {t('AiLlmIntegrationDescription')}
                        </p>
                        <div className="ai-page__hero-text-button" data-aos="fade-right" data-aos-delay="500" data-aos-offset="-300">
                            <Link to='ai-page__main'>
                                <Button >{t('GetStarted')}</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="ai-page__hero-arrows" onClick={handleClick}>
                        <AnimatedArrowsDown colors={['#FFF', '#FFF', '#FFF']} />
                    </div>
                </div>
            </div>
            <div className="ai-page__main">
                <Suspense fallback={<div></div>}>
                    <TitleContentCard contentObjectName="AiPage" />
                    <ContentCard {...aiContentCard} />
                    <ChatBotPointer />
                    <ContentCard {...aiContentCard2} />
                    <Footer />
                </Suspense>
                <br />
                <br />
            </div>
        </section>
    )
}

export default Ai
