
import './LanguageSelectBar.styles.scss';
import { GB } from 'country-flag-icons/react/3x2'
import { NL } from 'country-flag-icons/react/3x2'
import { BG } from 'country-flag-icons/react/3x2'
import { useState, useEffect, useRef, useMemo } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom"

type Languages = 'en' | 'nl' | 'bg'

const LanguageSelectBar = () => {
    const navigate = useNavigate();

    let { lng, extra } = useParams();
    const { lng: urlLng } = useParams();

    const { i18n } = useTranslation()

    const [language, setLanguage] = useState<Languages>(lng as Languages);
    const [isFlagMenuToggled, setIsFlagMenuToggled] = useState(false);


    const handleLanguage = (lng: Languages) => {
        const currentPath = window.location.pathname;
        let newPath = '/';
        if (currentPath == newPath) {
            newPath = `/en/`
            lng = 'en'
        }
        newPath = (urlLng) ? currentPath.replace(`/${urlLng}/`, `/${lng}/`) : `/${lng}${currentPath}`;
        if (currentPath.length <= 4) {
            newPath = `/${lng}/`
        }
        navigate(newPath, { replace: true });
        setIsFlagMenuToggled(false);
        i18next.changeLanguage(lng)
        setLanguage(lng);
    }

    const handleFlagMenu = () => {
        setIsFlagMenuToggled(!isFlagMenuToggled);
    }

    const blocking = useRef(false);
    const prevScrollY = useRef(0);

    const updateScrollDirection = () => {
        const scrollY = window.scrollY;

        if (scrollY !== prevScrollY.current && isFlagMenuToggled) {
            setIsFlagMenuToggled(false);
        }
        prevScrollY.current = scrollY
        blocking.current = false;
    }

    const handleScrollMenuVizbility = () => {
        if (!blocking.current) {
            blocking.current = true;
            window.requestAnimationFrame(updateScrollDirection);
        }
    }

    const memoizedHandleScrollMenuVizability = useMemo(() => handleScrollMenuVizbility, [handleScrollMenuVizbility]);
    // Add event listeners for window resize and scroll using memoized callbacks
    useEffect(() => {
        prevScrollY.current = window.scrollY;
        window.addEventListener('scroll', memoizedHandleScrollMenuVizability);
        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('scroll', memoizedHandleScrollMenuVizability);
        };
    }, [memoizedHandleScrollMenuVizability]);

    useEffect(() => {
        const defaultLanguage = i18n.language;
        if (defaultLanguage == 'en' || defaultLanguage == 'bg' || defaultLanguage == 'nl') {
            setLanguage(defaultLanguage)
        } else {
            setLanguage('en')
        }
    }, [i18n.language])

    return (
        <div className='LanguageSelectBar'>
            <div className={!isFlagMenuToggled ? 'LanguageSelectBar__flag' : 'LanguageSelectBar__flag--none'} onClick={handleFlagMenu}>
                <GB title="English" className={`LanguageSelectBar__flag-option LanguageSelectBar__flag-option-en--${language}`} onClick={() => handleLanguage('en')} />
                <NL title="Nederlands" className={`LanguageSelectBar__flag-option LanguageSelectBar__flag-option-nl--${language}`} onClick={() => handleLanguage('nl')} />
                <BG title="Български" className={`LanguageSelectBar__flag-option LanguageSelectBar__flag-option-bg--${language}`} onClick={() => handleLanguage('bg')} />
            </div >
            <div className={isFlagMenuToggled ? `LanguageSelectBar__menu LanguageSelectBar__menu--active` : `LanguageSelectBar__menu`}>
                <GB title="English" className="LanguageSelectBar__menu-option" onClick={() => handleLanguage('en')} />
                <NL title="Nederlands" className="LanguageSelectBar__menu-option" onClick={() => handleLanguage('nl')} />
                <BG title="Български" className="LanguageSelectBar__menu-option" onClick={() => handleLanguage('bg')} />
            </div>
        </div >
    )
};

export default LanguageSelectBar