import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {ns} from './i18nDataNs';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // init i18next ----for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      laodPath: '/locales/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    // debug: true, // diabled in production
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ',',
    },
    ns: ns, //NameSpaces - this is how to divide large translation to small parts and load on demand
  });

export default i18n;
