// import Button from '../button/button.component';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AnimatedArrowsDown from '../../../components/animated-arrows-down/AnimatedArrowsDown.component';
import './hero.styles.scss';
// import LoadImage from '../LoadImage/LoadImage.component';
import heroImage from '/assets/hero-images/hero-home-color.webp'
import heroImageMobile from '/assets/hero-images/hero-home-color-mobile.webp'
import heroImageLow from '/assets/hero-images/hero-home-low.webp'
// import { Link, animateScroll as scroll } from "react-scroll";


const Hero = () => {

    const { t, i18n } = useTranslation(["components/hero"]);

    const handleClick = () => {
        const scrollOffset = window.scrollY + window.innerHeight * 0.8;
        window.scrollTo({ top: scrollOffset, left: 0, behavior: 'smooth' });
    };

    return (
        <div className="hero-homepage-wrapper">
            <section className="hero-homepage" id='hero-homepage'>
                <div className="hero-homepage__card">
                    <div className='hero-homepage__card-text'>
                        <h2 data-aos="zoom-in-right" data-aos-delay="100" data-aos-offset="-300">{t("WebsitesWebApps")}</h2>
                        <h2 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t("AIIntegrations")}</h2>
                        <h2 data-aos="fade-right" data-aos-delay="300" data-aos-offset="-300">{t("UIUXDesign")}</h2>
                        <p
                            data-aos="fade-right"
                            data-aos-delay="400"
                            data-aos-offset="-300"
                        >
                            {t("AIDrivenWebDevelopment")}
                        </p>
                        {/* <div className="hero-homepage__card-text-button" data-aos="fade-right" data-aos-delay="500" data-aos-offset="-300">
                            <Link to='about-me-scroll-anchor' smooth={true}><Button buttonType='cherry-red'>{t("GetStarted")}</Button></Link>
                        </div> */}
                    </div>
                    <div className='hero-homepage__card-image' data-aos="zoom-in-left" data-aos-delay="800" data-aos-offset="-300">
                        <img
                            src={window.innerWidth <= 720 ? heroImageMobile : heroImage}
                            alt={'Red digit web development hero image'}
                        />
                        {/* <LoadImage
                            src={'/assets/hero-images/hero-home-desktop.webp'}
                            // srcLq={'/assets/hero-images/home-hero-new-low.webp'}
                            srcLq={'/assets/hero-images/hero-home-low.webp'}
                            alt={'Red digit web development hero image'}
                        /> */}
                    </div>
                </div>
                <div className="hero-homepage__card-arrows" onClick={handleClick}>
                    <AnimatedArrowsDown colors={['#D00', '#D00', '#D00']} />
                </div>
            </section>
        </div>
    );
};

export default Hero;