import './Contact.styles.scss'
import Button from '../../components/button/button.component';
import { lazy, Suspense, useState, useEffect } from 'react';
import AnimatedArrowsDown from '../../components/animated-arrows-down/AnimatedArrowsDown.component';
const Quote = lazy(() => import('../../components/quote/Quote.component'));
const Footer = lazy(() => import('../../components/footer/footer.component'));

// import LoadImage from '../../components/LoadImage/LoadImage.component';
import { Link } from 'react-scroll';
import contactHero from '/assets/hero-images/contact-1000p.webp'
import { useTranslation } from 'react-i18next';

import useComponentPath from '../../hooks/componentPath';

const Contact = () => {
    const { t } = useTranslation(["routes/contact"]);
    const quoteText = t('Quote').toUpperCase();

    useComponentPath('contact');

    const handleClick = () => {
        const scrollOffset = window.scrollY + window.innerHeight * 0.8;
        window.scrollTo({ top: scrollOffset, left: 0, behavior: 'smooth' })
    }

    return (
        <div className="contact-homepage-wrapper">
            <div className="contact-homepage" id='contact-homepage'>
                <div className="contact-homepage__hero-container">
                    <div className="contact-homepage__hero">
                        <div className='contact-homepage__hero-image' data-aos="zoom-in-left" data-aos-delay="100">
                            <img
                                src={contactHero}
                                alt={'Red Digit contact page image'}
                            />
                            {/* <LoadImage
                                src={'/assets/hero-images/contact-1000p.webp'}
                                srcLq={'/assets/hero-images/contact-1000p-low.png'}
                                alt={'Red Digit contact page image'}
                            /> */}
                        </div>
                        <div className='contact-homepage__hero-text'>
                            <h1 data-aos="zoom-in-right" data-aos-delay="100" data-aos-offset="-300">{t('ReadyToWork')}</h1>
                            <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('WeAreEager')}</h1>
                            <h1 data-aos="zoom-in-right" data-aos-delay="300" data-aos-offset="-300">{t('ToBuildTogether')}</h1>
                            <p
                                data-aos="fade-right"
                                data-aos-delay="400"
                                data-aos-offset="-300"
                            >
                                {t('BuildingForWebIsOurPassion')}
                            </p>
                            <div className="contact-homepage__hero-text-button" data-aos="fade-right" data-aos-delay="500" data-aos-offset="-300">
                                <Link to='footer'>
                                    <Button >{t('GetStarted')}</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="contact-homepage__hero-arrows" onClick={handleClick}>
                            <AnimatedArrowsDown colors={['#FFF', '#FFF', '#FFF']} />
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={<div></div>}>
                <Quote quoteText={quoteText} />
                <Footer />
            </Suspense>
        </div>
    )
}

export default Contact
