import './Ui-Ux.styles.scss'
import { lazy, Suspense, useEffect, useState } from 'react';

import AnimatedArrowsDown from '../../components/animated-arrows-down/AnimatedArrowsDown.component'
import Button from '../../components/button/button.component'
// import LoadImage from '../../components/LoadImage/LoadImage.component';

import uiuxHero from '/assets/hero-images/ui-ux-1000p.webp';
const ContentCard = lazy(() => import('../../components/content-card/ContentCard.component'));
const TitleContentCard = lazy(() => import('../../components/title-content-card/TitleContentCard.component'));
const Footer = lazy(() => import('../../components/footer/footer.component'));

import { ContentCardData } from '../../application-data/content-cards-data';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

import useComponentPath from '../../hooks/componentPath';



const UiUx = () => {
    const { t } = useTranslation(["routes/uiUx", "contentCards/uiUxContentCardUi", "contentCards/uiUxContentCardUx"]);
    const uiUxContentCardUi: ContentCardData = t('contentCards/uiUxContentCardUi:uiUxContentCard', { returnObjects: true })
    const uiUxContentCardUx: ContentCardData = t('contentCards/uiUxContentCardUx:uiUxContentCard', { returnObjects: true })

    useComponentPath('ui-ux');
    const handleClick = () => {
        const scrollOffset = window.scrollY + window.innerHeight * 0.8;
        window.scrollTo({ top: scrollOffset, left: 0, behavior: 'smooth' })
    }

    return (
        <section className="ui-ux-page">
            <div className="ui-ux-page__hero-container">
                <div className="ui-ux-page__hero">
                    <div className='ui-ux-page__hero-image' data-aos="zoom-in-left" data-aos-delay="100">
                        <img
                            src={uiuxHero}
                            alt={'Red Digit user interface and design image'}
                        />
                        {/* <LoadImage
                            src={'/assets/hero-images/ui-ux-1000p.webp'}
                            srcLq={'/assets/hero-images/ui-ux-1000p-low.png'}
                            alt={'Red Digit user interface and design image'}
                        /> */}
                    </div>
                    <div className='ui-ux-page__hero-text'>
                        <h1 data-aos="zoom-in-right" data-aos-delay="100" data-aos-offset="-300">{t('UserInterface')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="200" data-aos-offset="-300">{t('UserExperience')}</h1>
                        <h1 data-aos="zoom-in-right" data-aos-delay="300" data-aos-offset="-300">{t('ToolsExpertise')}</h1>
                        <p
                            data-aos="fade-right"
                            data-aos-delay="400"
                            data-aos-offset="-300"
                        >
                            {t('UiUxDescription')}
                        </p>
                        <div className="ui-ux-page__hero-text-button" data-aos="fade-right" data-aos-delay="500" data-aos-offset="-300">
                            <Link to='ui-ux-page__mui-uxn'>
                                <Button >{t('GetStarted')}</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="ui-ux-page__hero-arrows" onClick={handleClick}>
                        <AnimatedArrowsDown colors={['#FFF', '#FFF', '#FFF']} />
                    </div>
                </div>
            </div>
            <div className="ui-ux-page__mui-uxn">
                <Suspense fallback={<div></div>}>
                    <TitleContentCard contentObjectName="UiUxPageUi" />
                    <ContentCard {...uiUxContentCardUi} />
                    <TitleContentCard contentObjectName="UiUxPageUx" />
                    <ContentCard {...uiUxContentCardUx} />
                    <Footer />
                </Suspense>
                <br />
                <br />
            </div>
        </section>
    )
}

export default UiUx
