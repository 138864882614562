import React from 'react';
import './utils/i18n'
import './index.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './Layout/ScrollToTop.component';
// import { Suspense } from 'react';

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <BrowserRouter >
        <ScrollToTop />
        <App />
    </BrowserRouter>
    // </React.StrictMode>
)



