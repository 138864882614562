import { useRef, useState, useEffect } from "react";
// import { analitycs } from '../../../utils/firebase-utils';
import { logEvent } from 'firebase/analytics';
import { BsSend } from 'react-icons/bs';
import { BiSend } from 'react-icons/bi';
import './ai-input-field.styles.scss'
import axios from "axios";


type AiInputProps = {
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    setResponseMessage: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

const AiInputField = ({ setMessage, setIsFocused, message, setResponseMessage }: AiInputProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    // const vectorCallOld = async (message: string) => {
    //     if (!message) return;

    //     // const promptMessage = 'You are the helpful AI assistant of RedDigit. If you can determine the language of the question try to answer in the same language or use proper one. Try to answer with 1 to 5 sentances depending of the requested information. If the questions topic does not match RedDigits business answer your goal is to help customers about RedDigits expertise and give information about RedDigit. Following previous instrunctions answer the following question:';

    //     // const url = `http://127.0.0.1:5001/red-digit-ai-backend-8d3a5/europe-west1/redDigitAi?message=${message}`;
    //     // const url = `https://europe-west1-red-digit-ai-backend-8d3a5.cloudfunctions.net/redDigitAi?message=${message}`;
    //     const url = `https://europe-west1-red-digit-ai-backend-8d3a5.cloudfunctions.net/redDigitAi?message=${encodeURIComponent(message)}`;
    //     // const data = { message };
    //     try {
    //         const resData = await axios.get(url);
    //         const { response } = resData.data;
    //         return response;

    //         // const response = await fetch(url);
    //         // if (!response.ok) {
    //         //     const responseData = await response.json();
    //         //     throw new Error(`HTTP error! Status: ${responseData}`);
    //         // }
    //         // const responseData = await response.json();
    //         // const { response: messageResponse } = responseData;
    //         // return messageResponse;
    //     } catch (error) {
    //         return `I can't find reliable sources for this question. Could you try to rephrase it please? ${error}`;
    //     }
    // }

    const vectorCall = async (message: string) => {

        // const apiUrl =
        //     'http://127.0.0.1:5001/red-digit-ai-backend-8d3a5/europe-west1/vectorCall2';
        const { analitycs } = await import('../../../utils/firebase-utils')
        logEvent(analitycs, `Message from chat assistant: ${message}`);
        const apiUrl =
            'https://vectorcall2-k62xrkykhq-ew.a.run.app';

        const params = {
            message: message,
            prompt:
                "You are the helpful AI assistant of RedDigit. If you can determine the language of the question, try to answer in the same language. If you cannot, use the proper one. Try to answer with 1 to 5 sentences depending on the requested information. If the question's topic does not match RedDigit’s business, your goal is to help customers about RedDigit’s expertise and give information about RedDigit. Following previous instructions, answer the following question: ",
        };

        try {
            const resData = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            });
            // if (response.status === 200) {
            const reader = resData.body!.getReader();
            setResponseMessage((prevValue) => [...prevValue, message, ' ']);

            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const textChunk = new TextDecoder().decode(value);
                setResponseMessage(prevResponse => [...prevResponse.slice(0, -1), prevResponse.slice(-1) + textChunk]);
            }

            return;
        } catch (error) {
            console.error('Server side error:', error);
        }
    }



    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!message) {
            return
        }
        setIsFocused(false);
        setIsLoading(true);
        const response = await vectorCall(message);
        setIsLoading(false);
        setMessage('');
    }

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        // Delay the state update by a short time to allow the form submission to proceed
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    // inputRef.current?.focus();

    return (
        <div className="ai-chat-input-field">
            <form onSubmit={handleSubmit} className="ai-chat-input-field__form">
                <input
                    type='text'
                    className="ai-chat-input-field__form-input"
                    maxLength={150}
                    required
                    placeholder="your message"
                    value={message}
                    onChange={handleInputChange}
                    ref={inputRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {!isLoading
                    ? <button aria-label="send message" type='submit' className='ai-chat-input-field__form-button'><BiSend /></button>
                    : <span className="loading-spinner"></span>
                }
            </form>
        </div>
    )
}

export default AiInputField

