import i18next from 'i18next';
import {useParams, useNavigate} from 'react-router-dom';

import {useEffect} from 'react';

const useComponentPath = (componentPath: string) => {
    let {lng, extra} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        const urlPath = window.location.pathname;
        let path = lng ? `/${lng}/${componentPath}` : `/en/${componentPath}`;

        if (!lng) {
            lng = 'en';
            path = `/${componentPath}`;
        }

        if (lng && lng !== 'nl' && lng !== 'bg' && lng !== 'en') {
            lng = 'en';
            path = `/en/${componentPath}`;
        }

        i18next.changeLanguage(lng);
        return navigate(path, {replace: true});
    }, []);

    return {lng};
};

export default useComponentPath;
