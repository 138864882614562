// import { BsChatDots } from 'react-icons/bs'
import './assistant-chat-icon.styles.scss';
import ChatIcon from '../../../assets/ai-chat.png'
// import { analitycs } from '../../../utils/firebase-utils';
import { logEvent } from 'firebase/analytics';
type ChatBaloonProps = {
    isChatActive: boolean;
    setIsChatActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssistantChatIcon = ({ isChatActive, setIsChatActive }: ChatBaloonProps) => {
    const handleChat = async () => {
        const { analitycs } = await import('../../../utils/firebase-utils')
        logEvent(analitycs, `Chat assistant opened`);
        setIsChatActive(!isChatActive);
    }

    return (
        <div className={`assistant-chat-icon-container assistant-chat-icon-container--${isChatActive ? 'hidden' : 'block'}`}>
            <button aria-label="ai chat" onClick={handleChat} className='assistant-chat-icon-container__btn'>
                {/* <p className='assistant-chat-icon-container__btn__text'>AI</p> */}
                <div className='assistant-chat-icon-container__btn__icon'>
                    <img src={ChatIcon} alt="assistant chat icon" />
                    {/* <ChatIcon /> */}
                </div>
            </button>
        </div>
    )
}

export default AssistantChatIcon