import './AnimatedArrowsDown.styles.scss'

type Props = {
    colors: string[],
}
const AnimatedArrowsDown = ({ colors }: Props) => {
    return (
        <div className="animated-arrows-down">
            <div
                className="animated-arrows-down__arrow"
                style={{
                    borderRight: `6px solid ${colors[0]}`,
                    borderBottom: `6px solid ${colors[0]}`
                }}>
            </div>
            <div
                className="animated-arrows-down__arrow"
                style={{
                    borderRight: `6px solid ${colors[1]}`,
                    borderBottom: `6px solid ${colors[1]}`
                }}>
            </div>
            <div
                className="animated-arrows-down__arrow"
                style={{
                    borderRight: `6px solid ${colors[2]}`,
                    borderBottom: `6px solid ${colors[2]}`
                }}>

            </div>
        </div>
    )
}

export default AnimatedArrowsDown