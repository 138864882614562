import './home.styles.scss'
import Hero from './hero-section/hero.component'
import { lazy, Suspense, useEffect } from 'react';
import CountersSection from '../../components/counters-section/CountersSection.component';

const AboutMeSection = lazy(() => import('../../components/about-me-section/about-me-section.component'));
const FeaturesSection = lazy(() => import('../../components/features-section/Features-section.component'));
const ContentCard = lazy(() => import('../../components/content-card/ContentCard.component'));
const Footer = lazy(() => import('../../components/footer/footer.component'));
import { useTranslation } from 'react-i18next';
import { ContentCardData } from '../../application-data/content-cards-data';

import { useParams, useNavigate } from 'react-router-dom';
import i18next from 'i18next';

const Home = () => {

    const { t } = useTranslation(["contentCards/homeContentCard"])
    const homeContentCard: ContentCardData = t('homeContentCard', { returnObjects: true })

    const { extra } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let lng = 'en'
        let path = '/'

        if (extra == 'nl' || extra == 'bg' || extra == 'en') {
            lng = extra
            path = `/${lng}/`
        }
        i18next.changeLanguage(lng);
        navigate(path, { replace: true })
    }, []);

    return (
        <div className='home'>
            <Hero />
            <CountersSection />
            <Suspense fallback={<div></div>}>
                <AboutMeSection />
                <ContentCard {...homeContentCard} aspectRatio='1599/924' />
                <FeaturesSection />
                <Footer />
            </Suspense>
        </div>
    )
}
export default Home