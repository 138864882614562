import { useState } from 'react'
import AiChatCard from './components/ai-chat-card/ai-chat-card.component.tsx';
import AssistantChatIcon from './components/asisstant-chat-icon/assistant-chat-icon.tsx';
import './ai-assistant.styles.scss'
import { useTranslation } from 'react-i18next';

export const AiAssistant = () => {
    const { t } = useTranslation(["aiAssistant/aiAssistant"]);

    const user = t('UserMessageExample');
    const aiAssistant = t('AiAssistantGreeting');

    const [isChatActive, setIsChatActive] = useState(false);
    const [responseMessage, setResponseMessage] = useState<string[]>([user, aiAssistant]);
    const [message, setMessage] = useState('');

    return (
        <section className='ai-assistant-wrapper' >
            <AiChatCard
                responseMessage={responseMessage}
                setResponseMessage={setResponseMessage}
                message={message}
                setMessage={setMessage}
                isChatActive={isChatActive}
                setIsChatActive={setIsChatActive}
            />
            <AssistantChatIcon isChatActive={isChatActive} setIsChatActive={setIsChatActive} />
        </section>
    )
}


