import './CountersSection.styles.scss'
import Counter from '../counter/Counter.component'
import { useTranslation } from 'react-i18next'

type CountersData = {
    countFrom: number,
    countTo: number,
    content: string,
    countAddOn: string,
}
const CountersSection = () => {
    const { t } = useTranslation(["components/countersHomeData"])
    const countersHomeData: CountersData[] = t('countersHomeData', { returnObjects: true })
    return (<section className='counters-section'>
        {countersHomeData
            ? countersHomeData.map((counterData, index) => {
                return (
                    <Counter
                        key={index * 1}
                        countFrom={counterData.countFrom}
                        countTo={counterData.countTo}
                        content={counterData.content}
                        countAddOn={counterData.countAddOn}
                    />
                )
            })
            :
            <></>
        }
    </section>

    )
}
export default CountersSection
