
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

import Home from './routes/home/home.component';
import { lazy, Suspense } from 'react';
import Layout from './Layout/Layout.component';
import UiUx from './routes/ui-ux/Ui-Ux.component';
import Contact from './routes/contact/contact.component';
import Ai from './routes/ai/Ai.component';
import Development from './routes/development/development.component';
import { AiAssistant } from './chat-ai/ai-assistant';
import { useTranslation } from 'react-i18next';
const Blog = lazy(() => import('./routes/blog/Blog.component'));
const CreateBlogPost = lazy(() => import('./routes/createBlogPost/CreateBlogPost.component'));
const BlogPost = lazy(() => import('./components/blog-post/BlogPost.component'));
// const Footer = lazy(() => import('../../components/footer/footer.component'));

import AOS from 'aos'
import "aos/dist/aos.css";

const App = () => {
    const { t, i18n } = useTranslation(["common/common"]);

    useEffect(() => {
        /* ! important how to init AOS in Vite */
        AOS.init({
            easing: 'ease-in',
            delay: 50,
            duration: 300
        })
    }, []);

    useEffect(() => {
        // Update the meta description based on the selected language
        const description = t('metaDescription'); // 'metaDescription' should be defined in your translation files
        document.querySelector('meta[name="description"]')!.setAttribute('content', description);
    }, [i18n.language]);

    return (
        <div className='App-routes-container'>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path={`:lng/development`} element={<Development />} />
                    <Route path={`:lng/ai-integrations`} element={<Ai />} />
                    <Route path={`:lng/ui-ux`} element={<UiUx />} />
                    <Route path={`:lng/contact`} element={<Contact />} />
                    <Route path={`:lng/blog`} element={
                        <Suspense fallback={<div></div>}>
                            <Blog />
                        </Suspense>} />
                    <Route path={`:lng/blog/:id`} element={
                        <Suspense fallback={<div></div>}>
                            <BlogPost />
                        </Suspense>} />
                    <Route path={`/development`} element={<Development />} />
                    <Route path={`/ai-integrations`} element={<Ai />} />
                    <Route path={`/ui-ux`} element={<UiUx />} />
                    <Route path={`/contact`} element={<Contact />} />
                    <Route path={`/createBlogPost`} element={
                        <Suspense fallback={<div></div>}>
                            <CreateBlogPost />
                        </Suspense>} />
                    <Route path={`/blog`} element={
                        <Suspense fallback={<div></div>}>
                            <Blog />
                        </Suspense>} />
                    <Route path={`/blog/:id`} element={
                        <Suspense fallback={<div></div>}>
                            <BlogPost />
                        </Suspense>} />
                    <Route path={`/:extra`} element={<Home />} />
                    <Route path={`/*`} element={<Home />} />
                </Route>
            </Routes>
            {/* <div style={{ zIndex: '999', boxSizing: 'content-box', width: '1px', height: '1px' }}>
                <iframe
                    style={{ position: 'fixed', bottom: 0, right: 0, border: '0px' }}
                    // src="https://ai-assistant-1fa61.web.app/"
                    src="http://localhost:5175/"
                    title="Your iFrame Title"
                />
            </div> */}
            <AiAssistant />
        </div>
    )
}

export default App;
