export interface Item {
    name: string;
    offset?: number;
    contentPosition: number;
    url?: string;
    children?: Item[];
}

export const items: Item[] = [
    {
        name: 'HOME',
        url: '/',
        offset: 0,
        contentPosition: 1,
    },
    {
        name: 'DEVELOPMENT',
        url: '/development',
        offset: 50,
        contentPosition: 2,
    },
    {
        name: 'AI',
        url: '/ai-integrations',
        offset: 0,
        contentPosition: 3,
    },
    {
        name: 'UI/UX',
        url: '/ui-ux',
        offset: 0,
        contentPosition: 4,
    },
    {
        name: 'CONTACT',
        url: '/contact',
        offset: -100,
        contentPosition: 5,
    },
    {
        name: 'BLOG',
        url: '/blog',
        offset: 100,
        contentPosition: 6,
    },
    // { name: 'Testimonials', url: 'footer__contact__testimonials-title', offset: -100, contentPosition: 4 },
];
