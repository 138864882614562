import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { analitycs } from '../../utils/firebase-utils';
import { logEvent } from 'firebase/analytics';
import logo from '../../assets/logo_reddigit_desktop.svg';

// import i18next from 'i18next';
import { Item } from '../../application-data/navbar-config'
import { useState } from 'react';
import './navigation.style.scss'
import { FaAngleDown } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom';
import LanguageSelectBar from '../languge-select-bar/LanguageSelectBar.component';
import { useTransition } from 'react';

interface NavigationProps {
    items: Item[];
}

const Navigation = ({ items }: NavigationProps) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const lng = i18n.language

    const { t: t } = useTranslation(["navbar/navbar"]);
    const { t: c } = useTranslation(["common/common"]);


    const [isToggled, setIsToggled] = useState(false);
    const [closeSubMenu, setCloseSubMenu] = useState(false);

    const [scrollClass, setScrollClass] = useState(false)
    const [visible, setVisible] = useState(true)

    const THRESHOLD = 0;
    const blocking = useRef(false);
    const prevScrollY = useRef(0);

    const screenSizes = {
        small: 720
    }

    const handleScrollMenuTransparency = () => {
        const scrollY = window.scrollY;
        if (scrollY > 6 && !scrollClass) {
            setScrollClass(true);
        }
        if (scrollY <= 2) {
            setScrollClass(false);
        }
    }

    const updateScrollDirection = () => {
        const scrollY = window.scrollY;

        if (scrollY - prevScrollY.current > THRESHOLD && visible && scrollY > 500) {
            setIsToggled(false);
            setVisible(false);
        }

        else if (scrollY - prevScrollY.current + 10 <= THRESHOLD && !visible) {
            setVisible(true);
        }
        prevScrollY.current = scrollY
        blocking.current = false;
    }

    const handleScrollMenuVizbility = () => {
        if (!blocking.current) {
            blocking.current = true;
            window.requestAnimationFrame(updateScrollDirection);
        }
    }

    const memoizedHandleScrollMenuTransparency = useMemo(() => handleScrollMenuTransparency, [handleScrollMenuTransparency]);
    const memoizedHandleScrollMenuVizability = useMemo(() => handleScrollMenuVizbility, [handleScrollMenuVizbility]);


    // Add event listeners for window resize and scroll using memoized callbacks
    useEffect(() => {
        prevScrollY.current = window.scrollY;

        window.addEventListener('scroll', memoizedHandleScrollMenuTransparency);
        window.addEventListener('scroll', memoizedHandleScrollMenuVizability);
        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('scroll', memoizedHandleScrollMenuTransparency);
            window.removeEventListener('scroll', memoizedHandleScrollMenuVizability);
        };
    }, [memoizedHandleScrollMenuTransparency, memoizedHandleScrollMenuVizability]);

    const toggleSubMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.currentTarget.classList.toggle('navbar-container__menu-sub-menu--toggled');
    }

    const renderItems = () => items.map((item, index) =>
        <li key={index} onClick={() => closeMenu(true)}>
            {item.url
                ? <a href={`${c('languageCode')}${item.url}`}
                    onClick={async (e) => {
                        e.preventDefault();
                        const { analitycs } = await import('../../utils/firebase-utils')
                        logEvent(analitycs, `Page clicked: ${item.url}`);
                        handleNavigate(`${c('languageCode')}${item.url}`!)
                    }}
                >{t(`${item.name}`)}</a>
                : <div onClick={toggleSubMenu} className='navbar-container__menu-item'>
                    <span>
                        {t(item.name)}
                        <FaAngleDown className='navbar-container__menu-arrow-icon' />
                    </span>
                    {item.children && renderChildren(item.children)}
                </div>
            }
        </li>
    )

    const renderChildren = (children: Item[]) => (
        <ul className="navbar-container__menu-sub-menu navbar-container__menu-sub-menu--toggled">
            {children.map((child, index) => (
                <li key={index} onClick={() => closeMenu(true)}>
                    <Link
                        to={child.url!}
                    > {child.name}
                    </Link>
                </li>
            ))}

        </ul>
    )

    const closeMenu = (closeSubMenu = false) => {
        setIsToggled(false);
        if (closeSubMenu && window.innerWidth > screenSizes.small) {
            setCloseSubMenu(true);
            setTimeout(() => setCloseSubMenu(false), 0);
        }
    }

    const handleNavigate = (path: string) => navigate(path);

    const activeClass = (scrollClass || isToggled) ? ' main-navbar--onscroll' : '';
    const menuVisibleClass = (visible) ? ' main-navbar--visible' : '';
    const navbarContainerClasses =
        [
            'navbar-container__menu',
            isToggled && 'navbar-container__menu--active',
            closeSubMenu && 'navbar-container__menu-sub-menu--toggled',
        ].filter(Boolean).join(' ')

    return (
        <nav className={`main-navbar ${activeClass}${menuVisibleClass}`}>
            <div className="navbar-container">
                <div className="navbar-container__logo">
                    <img src={logo} alt="Company logo" onClick={() => navigate(`/${lng}/`, { replace: true })} />
                    <LanguageSelectBar />
                </div>

                <div className={`navbar-container__hamburger`} onClick={() => setIsToggled(!isToggled)}>
                    <span className={`navbar-container__hamburger-burger-bar navbar-container__hamburger${isToggled ? '--close' : ''}-burger-bar-top`}></span>
                    <span className={`navbar-container__hamburger-burger-bar navbar-container__hamburger${isToggled ? '--close' : ''}-burger-bar-middle-1`}></span>
                    <span className={`navbar-container__hamburger-burger-bar navbar-container__hamburger${isToggled ? '--close' : ''}-burger-bar-middle-2`}></span>
                    <span className={`navbar-container__hamburger-burger-bar navbar-container__hamburger${isToggled ? '--close' : ''}-burger-bar-bottom`}></span>
                </div>
            </div>
            <div className={`navbar-container__menu-block ${isToggled ? 'navbar-container__menu-block--extended' : ''}`}>
                <ul className={navbarContainerClasses}>
                    {renderItems()}


                </ul>
                <p className={`navbar-container__menu-block-footer ${isToggled ? 'navbar-container__menu-block-footer--extended' : ''}`}>
                    {/* Web-development | UI/UX design | AI integrations | Stripe payments */}
                    AI driven web development
                </p>
            </div>

        </nav>
    )
}

export default Navigation